.newpwd {
  @include login-flow-form;
  &__title {
    @include font-style-Baloo(normal, 400, 24px, 24px, $black);
  }
  &__sub-title {
    @include font-family-width(normal, 400, 14px, 21px, $lightGrey);
  }
  &__pwdicon {
    position: absolute;
    right: 0rem;
    padding: 0.9375rem;
  }
  &__mobilePrefix {
    @include font-family-width(normal, 500, 1rem, 1.875rem, $black);
    position: absolute;
    padding: 0.75rem 0.75rem;
  }
  &__pass-wrapper {
    position: relative;
    margin-bottom: 0.875rem;
  }

  &__pl-45 {
    padding-left: 2.8125rem;
  }

  &_pwdicon:hover {
    color: green;
    cursor: pointer;
  }
  .instructions {
    font-weight: 500;
    font-style: normal;
    font-size: 12px !important;
    line-height: 18px;
    color: $orange;
    text-transform: none;
  }
}
