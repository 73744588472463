.subSettings {
  &__container {
    background: $white;
    border-radius: 50px;
    padding: 30px 30px 60px 30px;
  }

  &__back-btn {
    display: flex;
    align-items: center;
    cursor: pointer;

    &__back-text {
      padding-left: 0.625rem;
      font-weight: 600;
      font-style: normal;
      font-size: 1.125rem;
      line-height: 1.25rem;
      color: $lightGrey;
    }
  }
  &__heading {
    font-size: 18px;
    font-weight: 700;
    line-height: 23.25px;
    color: $lightPurple7;
    margin-bottom: 20px;
  }
  .subHedding {
    @include font-family-width(normal, 600, 16px, 24px, $grey80);
    letter-spacing: 0em;
    text-align: left;
    margin: 10px 0 16px 0;

    &__content {
      @include font-family-width(normal, 400, 14px, 21px, $grey90);
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 12px;
      cursor: pointer;
      a {
        color: $grey90;
      }
    }
  }
  .subHeddingCustom {
    cursor: pointer;
  }
}
