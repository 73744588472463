.player {
  &__player-container {
    width: 100%;
    height: 708px;
  }
}
.video-js{
  background-color: white;
}
@media only screen and (max-width: 479px) {
  .player {
    &__player-container {
      width: 100%;
      height: 206px;
    }
  }
}
@media only screen and (max-width: 780px) and (min-width: 480px) {
  .player {
    &__player-container {
      width: 100%;
      height: 425px;
    }
  }
}
