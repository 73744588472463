@font-face {
	font-family: 'KOMTITP';
	src: local('KOMTITW'), url('../fonts/KOMTITW/KOMTITW.woff') format('woff');
}

html{
	height: 100%;
}

.audio{
	position: absolute;
	top: 5%;
	right: 20px;
	background: none;
	border: none;
}
.mob-audio{
	position: absolute;
	top: 15%;
	right: 10px;
	background: none;
	border: none;
	z-index: 1000;
	cursor: pointer;
}

.mob-painnextbg{
	width: 100%;
	height: 750px;
	background: url("../images/event/mob-paintathon-page02.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.mob-conanbg{
	background: #151247;
	height: 700px;
}

.mob-pain-nxtpg-cont{
	padding-top: 80px;
	text-align: center;
}



.mob-pain-nxtpg-cont img{
	width: 100%;
}

.mob-pain-nxtpgbt{
	text-align: center;
	margin-top: 25px;
}

.mob-conan-nxtgb{
	text-align: center;
	margin-top: -280px;
}

.mob-conan-nxtgb img{
	width: 100px;
}

.mob-conan-win-box{
	text-align: center;
	margin: -420px auto 0px auto;
}

.conan-gpa{
	width: 70px;
	height: 70px;
	margin: auto;
	background: #000000;
	border-radius: 10px;
	display: inline-flex;
	border-top: #EBE7E7 2px solid;
	border-bottom: #EBE7E7 2px solid;
	overflow: hidden;
	}

	.gp-tit{
	background: #151247;
	padding: 2px 5px;
	border: 1px solid #bb9cb7;
	color: #fff;
	z-index: 99999;
	border-radius: 10px;
	font-family: 'KOMTITP';
	font-size: 8px;
	display: inline-flex;
	margin-bottom: 2px;
	}

.win-candidatenm{
	font-family: 'KOMTITP';
	font-size: 8px;
	color: #fff;
	margin-bottom: 5px;
}

/* .mob-gps{
	margin-top: 15px;
	
} */
 .pain-winlist-box h1,.pain-winlist-box24 h1,.pain-winlist-box23 h1{
	font-family: 'KOMTITP';
	text-transform: uppercase;
	font-size: 10px;
	color: #fe830a;
	font-weight: bold;
	margin: 10px;
}


.mob-gps ul{
	padding: 0px;	
}

.mob-gps ul li{
display: inline-block;
width: 100px;
	
}

.mob-pain-nxtpgbt img{
	width: 120px;
}

.mob-pain-winlist{
	width: 100%;
	height: 100%;
	background: url("../images/event/mob-paintathon-bg.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-bottom: 30px;
	
}

.mob-pain-winyrs{
	width: 100%;
	height: 100%;	
	padding-top: 50px;
}

.mob-pain-yrs21{
	width: 100%;
	height: 100%;
	padding-top: 70px;
	padding-bottom: 30px;
}

.mob-pain-yrs23{
	width: 100%;
	height: 100%;
	margin-top: 0px;
	padding-bottom: 30px;
}

.mob-pain-yrs{
	position: relative;
	top: -345px;
	text-align: center;
/*	margin-top: -265px*/
}

.mob-pain-yrs ul{
	margin: 0px;
	padding: 0px;
}

.mob-pain-yrs ul li{
	list-style-type: none;
	margin-bottom: 30px;
}

.mob-pain-yrs ul li img{
	width: 100px;
}


.pain-winlist-box{
	width: 100%;
	height: 450px;
	margin-top: -495px;	
	z-index: 999999;
	text-align: center;
		
}
.pain-winlist-box23{
	width: 100%;
	height: 550px;
	margin-top: -542px;	
	z-index: 999999;
	text-align: center;
		
}

.pain-winlist-box24{
	width: 100%;
	height: 533px;
	margin-top: -500px;	
	z-index: 999999;
	text-align: center;
}

.pain-winlist-box h1,.pain-winlist-box24 h1,.pain-winlist-box23 h1{
	font-family: 'KOMTITP';
	text-transform: uppercase;
	font-size: 12px;
	color:#fe830a ;
	font-weight: bold;
}



.win-container,.mega-win-container{
	text-align: center;
}

.win-container ul,.mega-win-container ul{
	margin: 0px;
	padding: 0px;
}

.win-container ul li{
	width: 80px;
	height: 75px;
	background: #000000;
	border-radius: 10px;
	display:inline-flex;
	border-top: #EBE7E7 2px solid;
	border-bottom: #EBE7E7 2px solid;
	overflow: hidden;
	
	
}

.mega-win-container ul li{
	width: 75px;
	height: 65px;
	background: #000000;
	border-radius: 10px;
	display:inline-flex;
	border-top: #EBE7E7 2px solid;
	border-bottom: #EBE7E7 2px solid;
	overflow: hidden;
	margin-left: 24px;
	
	
}

.win-container ul li img,.mega-win-container ul li img{
	width: 100%;
	object-fit: cover;
}

.mega-win{
	width: 90px;
	height: 80px;
	background: #000;
	border-radius: 30px;
	display:inline-flex;
	border-top: #EBE7E7 2px solid;
	border-bottom: #EBE7E7 2px solid;
	overflow: hidden;
}


.new-body {
  background: #fff;
  z-index: 999999;
}

.new-mod-head{
	background: transparent!important;
	border: none;
	
}

.new-close{
	position: absolute;
	top: 0px;
	right: 15px!important;
}

.drw-cand-name{
		text-align:center;
	font-family: 'KOMTITP';
	padding-top:10px;
	font-size:12px;
}

img {
  vertical-align:inherit!important;
  border-style: none;
}


 .zoom,.zoombt{
	transition: transform .3s ease;
}

.zoom:hover {
  transform: scale(1.05);
}

.zoombt:hover {
  transform: scale(1.1);
}

.nxpgbtn-pain{
	position: absolute;
	top: 37%;
	left: 35%;
}

.nxpgbtn-conan{
	position: absolute;
	top: 57%;
	left: 37%;
}

.winlis-tit{
	font-family: 'KOMTITP';
	color: #fff;
	background: #fe830a;
	padding: 5px 10px;
	border-radius: 10px;
	font-size: 12px;
	
}

.artgal{
	text-align: center;
	font-family: 'KOMTITP';
	color: #000;
	margin-top: 10px;
}


.artgal a{
	font-family: 'KOMTITP';
	color: #000;
	background: #fff;
	font-size: 10px;
	padding: 5px 10px;
	border-radius: 10px;
	
}

.artist-main{
		height: 350px;
		overflow-y: scroll;
	}

@media (max-width: 390px) { 
	
	.pain-winlist-box {
	width: 100%;
	height: 450px;
	margin-top: -420px;
	z-index: 999999;
	text-align: center;
	}
	
	.mob-pain-yrs{
	position: relative;
	top: -300px;
	text-align: center;
/*	margin-top: -265px*/
}
	
.win-container ul li {
  width: 68px;
  height: 60px;
  background: #000000;
  border-radius: 10px;
  display: inline-flex;
  border-top: #EBE7E7 2px solid;
  border-bottom: #EBE7E7 2px solid;
  overflow: hidden;
}
	
.mob-pain-yrs23 {
  width: 100%;
  height: 100%;
  margin-top: -45px;
  padding-bottom: 30px;
}
.pain-winlist-box23 {
  width: 100%;
  height: 564px;
  margin-top: -491px;
  z-index: 999999;
  text-align: center;
}
	
.pain-winlist-box24 {
  width: 100%;
  height: 450px;
  margin-top: -425px;
  z-index: 999999;
  text-align: center;
}
	
.mega-win {
  width: 75px;
  height: 75px;
  background: #000;
  border-radius: 30px;
  display: inline-flex;
  border-top: #EBE7E7 2px solid;
  border-bottom: #EBE7E7 2px solid;
  overflow: hidden;
}
	
.mega-win-container ul li{
	width: 60px;
	height: 55px;
	background: #000000;
	border-radius: 10px;
	display:inline-flex;
	border-top: #EBE7E7 2px solid;
	border-bottom: #EBE7E7 2px solid;
	overflow: hidden;
}
	
.pain-winlist-box h1, .pain-winlist-box24 h1, .pain-winlist-box23 h1 {
  font-family: 'KOMTITP';
  text-transform: uppercase;
  font-size: 10px;
  /* color: #fff; */
  font-weight: bold;
}
	
.mob-conan-nxtgb {
  text-align: center;
  margin-top: -240px;
}
	
.mob-conan-win-box {
  text-align: center;
  margin: -390px auto 0px auto;
}
	
.artist-main{
height: 310px;
overflow-y: scroll;
}
	
	
}
.win-container li {
	margin-right: 5px;   
	margin-bottom: 5px;  
	flex: 0 0 auto;       
  }


/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) { 
	
	.mob-painnextbg{
	width: 100%;
	height: 1380px;
	background: url("../images/event/mob-paintathon-page02.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
	
.mob-conanbg{
	background: #151247;
	height: 1200px;
}
	
	.mob-conan-nxtgb{
	text-align: center;
	margin-top: -550px;
}

.mob-conan-nxtgb img{
	width: 140px!important;
}
	
	.mob-pain-nxtpg-cont img{
		padding-top: 80px;
		width: 600px;
}
	
	.mob-pain-nxtpgbt{
		margin-top: 0px;
	}
	.mob-pain-nxtpgbt img{
		width: 180px;
		padding-top: 30px!important;
		
	}
	
	.mob-pain-winyrs{
	width: 60%;
	height: 500px;	
	padding-top: 50px;
		margin: auto;
}
	
	
	.mob-pain-winlist{
		height: 1080px;
	}
	
	.mob-pain-yrs21{
	width: 60%;
	height: 500px;	
		margin: 0px auto 0px auto;
	}
	
	.mob-pain-yrs{
	position: relative;
	top: -380px;
	text-align: center;
/*		margin-top: -350px;*/
}

.mob-pain-yrs ul{
	margin: 0px;
	padding: 0px;
}

.mob-pain-yrs ul li{
	list-style-type: none;
	margin-bottom: 30px;
}

.mob-pain-yrs ul li img{
	width: 140px;
}
	
	.pain-winlist-box{
	width: 100%;
	height: 450px;
	margin-top: -595px;	
	z-index: 999999;
	text-align: center;
		
}
	
	.win-container ul li{
	width: 100px;
	height: 100px;
	background: #000000;
	border-radius: 10px;
	display:inline-flex;
	border-top: #EBE7E7 2px solid;
	border-bottom: #EBE7E7 2px solid;
	overflow: hidden;
	
	
}

.win-container ul li img{
	width: 100%;
	object-fit: cover;
}
	
.pain-winlist-box24{
	width: 100%;
	height: 450px;
	margin-top: -600px;	
	z-index: 999999;
	text-align: center;
}
	
	.winlis-tit {
  font-family: 'KOMTITP';
  color: #000;
  background: #f1f8e8;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 14px;
  
}
	
	.mega-win {
  width: 110px;
  height: 110px;
  background: #000;
  border-radius: 30px;
  display: inline-flex;
  border-top: #EBE7E7 2px solid;
  border-bottom: #EBE7E7 2px solid;
  overflow: hidden;
}
	
.mega-win-container ul li {
  width: 80px;
  height: 80px;
  background: #000000;
  border-radius: 10px;
  display: inline-flex;
  border-top: #EBE7E7 2px solid;
  border-bottom: #EBE7E7 2px solid;
  overflow: hidden;
}
	
	.mob-conan-win-box {
  text-align: center;
  margin: -720px auto 0px auto;
}
	.gp-tit {
  background: #151247;
  padding: 2px 5px;
  border: 1px solid #bb9cb7;
  color: #fff;
  z-index: 99999;
  border-radius: 10px;
  font-family: 'KOMTITP';
  font-size: 10px;
  display: inline-flex;
  margin-bottom: 2px;
}
	
.win-candidatenm {
font-family: 'KOMTITP';
font-size: 10px;
color: #fff;
margin-bottom: 5px;
}
	
.mob-gps ul li {
  display: inline-block;
  width: 160px;
}
	
	.conan-gpa {
	width: 100px;
	height: 100px;
	margin: auto;
	background: #000000;
	border-radius: 10px;
	display: inline-flex;
	border-top: #EBE7E7 2px solid;
	border-bottom: #EBE7E7 2px solid;
	overflow: hidden;
		
	}
	
	.conan-gpa img{
		width: 100%;
		padding-top: 0px!important;
	}
	
	.new-close{
	position: absolute;
	top: 0px;
	right: 0px!important;
}
	
	.close{
		opacity: 1;
	}
	
	.nxpgbtn-pain{
	position: absolute;
	top: 40%;
	left: 44%;
}

.nxpgbtn-conan{
	position: absolute;
	top: 60%;
	left: 44%;
}
	
	.artist-main{
		height: 430px;
		overflow-y: scroll;
	}
	
	

}




@media (min-width: 1023px){
	.mob-pain-yrs {
        position: relative;
        top: -575px;
        text-align: center;
        /* margin-top: -350px; */
    }
	
	.mob-pain-yrs ul li {
        list-style-type: none;
        margin-bottom: 30px;
    }
	
	.pain-winlist-box {
        width: 100%;
        height: 450px;
        margin-top: -700px;
        z-index: 999999;
        text-align: center;
    }
}
