
.modal.show {
  animation: slideInFromBottom 0.3s forwards;
  z-index: 1050; 
}

.modal.hide {
  animation: slideOutToBottom 0.2s forwards;
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOutToBottom {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}



.container{
 
  // margin-bottom:-45px;
  cursor: pointer;
  
}
// .main-container{
//   margin-top: -1%;
//   // padding-top: 2.5%;
  
// }


.art-gal {
  position: relative; 
  display: inline-block;
}

.art-gal::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 5px; 
  width: 95%;
  height: 1px; 
  background: black; 
  transform: scaleX(0); 
  transition: transform 0.3s ease;
}

.art-gal:hover::after {
  transform: scaleX(1); 
}

// mobpainwin2024
.mega-win-container li { 
  margin: 3px; 
}



.artgal {
  position: relative; 
  display: inline-block; 
}

.artgal::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 1px; 
  width: 95%;
  height: 1px; 
  background: black; 
  transform: scaleX(0); 
  transition: transform 0.3s ease;
}

.artgal:hover::after {
  transform: scaleX(1); 
}

.customCarousel {
  padding-top: 65px;
}

