.forgotpwd {
  @include login-flow-form;
  &__change-mobile {
    text-align: end;
  }
  &__change-mobile-btn {
    @include font-family-width(normal, 600, 12px, 18px, $skyBlue);
    cursor: pointer;
    border: none;
    background-color: $white;
  }
  &__otp {
    .col-md-7 {
      padding-right: 0px !important;
      padding-left: 0px !important;
    }
  }
  &__otp-timer {
    @include smallDisplay() {
      padding-right: 4%;
    }
    @include font-family-width(normal, 500, 14px, 21px, $grey80);
    text-align: right;
    display: flex;
    align-items: flex-end;
    margin-left: 1rem;
  }
  &__otp-box {
    @include flex-box;
    &__opt-input {
      input {
        @include font-family-width(normal, 500, 24px, 36px, $lightpurple80);
        text-align: center;
        width: 100% !important;
        max-width: 56px;
        height: 60px;
        border-radius: 12px;
        margin-right: 10px;
        border: 1px solid $lightGrey20;
      }
    }
    &__otp-error-msg {
      width: 100%;
      margin-top: 0.25rem;
      text-align: end;
      padding-right: 4%;
      @include font-family-width(normal, normal, 12px, 20px, $red);
    }
  }
  &__otp-resend {
    @include font-family-width(normal, 600, 12px, 20px, $skyBlue);
    cursor: pointer;
    border: none;
    background-color: $white;
  }
}
