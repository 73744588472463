.login {
  @include login-flow-form;
  &__pwdicon {
    position: absolute;
    right: 0rem;
    padding: 0.938rem;
  }
  &__mobilePrefix {
    position: absolute;
    padding: 0.688rem 0.75rem;
    @include font-family-width(normal, 500, 1rem, 1.875rem, $black);
  }
  &__pass-wrapper {
    position: relative;
    margin-bottom: 0.875rem;
  }

  &__pl-45 {
    padding-left: 2.813rem;
  }

  &_pwdicon:hover {
    color: green;
    cursor: pointer;
  }

  .metro {
    border: none;
    background: transparent;
  }

  &__social-login {
    &__title {
      @include font-style-Baloo(normal, normal, 1.5rem, 1.25rem);
      text-transform: uppercase;
      color: $black;
      opacity: 0.8;
    }
    &__sub-title {
      position: relative;
      text-transform: none !important;
      font-size: 14px;
      padding-right: 100px;
      font-family: $font-family-poppins !important;
      @include font-family-width(normal, normal, 0.875rem, 1.125rem);
      color: #acacac;
    }
  }
  &__userDetails {
    &__social-icon {
      position: absolute;
      margin: -19px 2px;
      background-color: #fff;
      padding: 9px;
      color: #000000;
      font-size: 0.75rem;
      border-radius: 50%;
    }
    &__container {
      padding: 20px 0px;
      margin-left: 20px;
      background: #ffffff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      display: flex;
      align-items: center;

      &__profile {
        width: 50px;
        height: 50px;
        text-transform: uppercase;
        background: $skyBlue;
        border-radius: 50%;
        margin: 0px 15px;
        &__text {
          @include font-family-width(normal, 600, 0.9rem, 2.5rem, $black);
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &__name {
          @include font-family-width(normal, 600, 14px, 20px, $black);
        }
        &__email {
          @include font-family-width(normal, 400, 14px, 20px, $black);
        }
      }
    }
  }

  &__verifyNumberTitle {
  }
  &__verifyNumberMessage {
  }
}