$black: #000000;
$lightRed: #eeb6ba;
$white: #fff;
$dimWhite: #f1f1f1;
$lightSilver: #d8d8d8;
$purple: #3d317a;
$red: #d84c54;
$red10: #fbedee;
$lightpurple: #673ab7;
$lightpurple80: #663ab7;
$shadedPurple: #64599c;
$skyBlue: #2196f3;
$darkYellow: #ffcf4b;
$orange: #ff824b;
$darkOrange: #eb5757;
$lightGrey: #747474;
$grey100: #acacac;
$grey80: #505565;
$success: #027d22;
$warning: #f1c40f;
$error: #e74c3c;
$magnolia: #ede6ff;
$lightWhite: #fffaed;
$lightpurple10: #f0ebf8;
$lightGrey20: #d3d4d8;
$red80: #f9265d;
$red05: #e91f63;
$grey90: #8b90a0;
$lightBlue: #2980b9;
$skyBlue: #2197f3;
$purple87: #463987;
$lightYellow: #fff5db;
$lightRed: #f399b1;
$red57: #eb5757;
$lightPurple7: #663ab7;
$whiteFa: #fafafc;
$graya1: #a1a4b1;
$darkgray:#4A5060;
$lightBlack: #505050;
$shadedRed: #c4c4c4;
$graya1: #a1a4b1;
$textAreaBg: #f0f1f3;
$blue: #3160e2;
$lightGray: #fafafa;
$lightPurple8: #ff85a5;
$darkBlue: #1a2058;
$lightYellow2: #ffa674;
$darkBlue2: #07052f;
$darkYellow2: #ffb500;
$darkYellow3: #fff200;
$greenLight: #1fb53a;
$blackTwo: #00000094;
$rede5: #e50300;
$lightGray2: #ccc;
$darkBLue3: #1f64fd;
