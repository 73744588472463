.shows {
  .showSeasonCard {
    border-radius: 10px;
    width: 100%;
    max-height: 162px;
  }
}
.toonsTransForm:hover {
  transform: scale(1.3);
  transition: all 300ms;
}
