a,
input,
select,
button {
  outline: none !important;
}
.main-background {
  @include smallDisplay() {
    background-image: url(../../assets/images/mobile_background.svg);
  }
  background-color: $purple;
  height: 100%;
  min-height: 100vh;
  background-image: url(../../assets/images/bg1.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.content-padding {
  @include smallDisplay() {
    padding: 1.25rem;
  }
  padding: 3.125rem;
}

.cursor-pointer {
  cursor: pointer !important;
}

// padding css
.p-b-40 {
  padding-bottom: 40px;
}
.p-tb-35 {
  padding: 35px 0px;
}
.p-b-15 {
  padding-bottom: 15px;
}
.p-b-20 {
  padding-bottom: 20px;
}
.padding-35 {
  padding: 35px;
}
.m-l-0 {
  margin-left: 0px;
}
.m-l-9 {
  margin-left: 9px;
}
.m-r-10 {
  margin-right: 10px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-l-r-20 {
  margin: 0 120px;
}
.p-14 {
  padding: 14px;
}

.whiteText {
  @include font-family-width(normal, 400, 18px, 20px, $white);
}
.whiteText:hover {
  @include font-family-width(normal, 400, 18px, 20px, $white);
}

.mapsMarker {
  height: 24px;
  width: 15px;
  position: absolute;
  top: -18px;
}
.back-btn {
  &__content {
    @include flex-box;
    position: relative;
    bottom: 3.75rem;
  }
  @include flex-box;
  cursor: pointer;
  position: absolute;
  margin-left: 0.625rem;
  &__back-text {
    color: $lightGrey;
    padding-left: 0.625rem;
    border: none;
    background-color: $white;
    @include font-family-width(normal, 600, 1.125rem, 1.25rem);
  }
}

.form-title {
  &__title {
    @include smallDisplay() {
      @include flex-box;
    }
    bottom: 3.4375rem;
    position: relative;
  }
  &__subtitle {
    position: relative;
    text-transform: none !important;
    font-size: 14px;
    padding-right: 100px;
    font-family: $font-family-poppins !important;
    @include font-family-width(normal, normal, 0.875rem, 1.125rem);
    color: #acacac;
  }
  padding-bottom: 1.25rem;
  position: absolute;
  @include font-style-Baloo(normal, normal, 1.5rem, 1.25rem);
  text-transform: uppercase;
  color: $black;
  opacity: 0.8;
}

.common-back-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 200px;
}
.common-back-btn-text {
  padding-left: 0.625rem;
  @include font-style-Baloo(normal, 600, 1.125rem, 1.25rem, $lightGrey);
}

.breadcrumb {
  background-color: $white;
  padding: 0;
  margin-bottom: 8px;
  &-item {
    @include font-family-Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #130f40;
  }
  &-item + &-item::before {
    content: '>';
    color: #130f40;
  }
}

.align-center {
  @include align-center;
}

.justify-center {
  @include justify-center;
}
.display-flex {
  @include flex-box;
}

.custom-switch {
  padding-left: 0rem;
}

.toaster-container {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 500px;
  height: auto;
  z-index: 999;
}

.toasterMsg {
  position: absolute;
  top: 20px;
  right: 5px;
  &__success {
    background-color: $success !important;
  }
  &__error {
    background-color: $error !important;
  }
  &__warning {
    background-color: $warning !important;
  }
  @include font-family-width(normal, 500, 16px, 20px, $white);
}

.pwd-eye-icon {
  &__pwd-wrapper {
    position: relative;
    margin-bottom: 0.875rem;
  }
  &__pwdicon {
    position: absolute;
    right: 0rem;
    padding: 0.9375rem;
  }
  &__pwdicon:hover {
    cursor: pointer;
  }
  &__update {
    position: absolute;
    right: 1rem;
    padding: 0.9375rem;
  }
  &__update:hover {
    cursor: pointer;
  }
}
.mobile-prefix {
  &__mobilePrefix {
    position: absolute;
    padding: 0.7rem 0.75rem;
    @include font-family-width(normal, 500, 1rem, 1.875rem);
  }
  &__pl-45 {
    padding-left: 2.8125rem !important;
  }
}

a:hover {
  text-decoration: none;
}

.isInvalid {
  background: $red10;
  border: 1px solid $red !important;
  color: $red !important;
}

// ======== Modal window start ==========================

.modal-content {
  // padding: 30px;
  border-radius: 12px;
  width: 100%;
}
.modal-header {
  border-bottom: none;
}
.modal-footer {
  border-top: none;
}

.modal-window {
  &__modal-title {
    margin-bottom: 15px;
    @include font-family-width(normal, 600, 14px, 21px);
  }
  &__modal-text {
    @include font-family-width(normal, 400, 12px, 19.2px);
  }
  
}


// ======== Modal window end ==========================

// ======== big avatar start ==========================
.avatar {
  &__big-avatar {
    // @include big-avatar-by-size(144px, 144px);
    max-width: 144px;
    max-height: 144px;
    min-width: 144px;
    min-height: 144px;
    vertical-align: middle;
    display: inline-block;
    background: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    text-align: center;
    border-radius: 50%;
    &__img {
      vertical-align: middle;
      border-radius: 50%;
      height: -webkit-fill-available;
      max-width: 100%;
      max-height: 100%;
    }
    border: 2px solid #3d317a;
  }
  &__big-avatar-144 {
    //    @include big-avatar-by-size(144px, 144px);
    max-width: 144px;
    max-height: 144px;
    min-width: 0px;
    min-height: 0px;
    vertical-align: middle;
    display: inline-block;
    background: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    text-align: center;
    border-radius: 50%;
    &__img {
      vertical-align: middle;
      border-radius: 50%;
      height: -webkit-fill-available;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    border: 2px solid #3d317a;
    &__name {
      @include font-style-Baloo(normal, 500, 24px, 38px, $lightpurple80);
    }
  }
  &__big-avatar-120 {
    @include big-avatar-by-size(120px, 120px);
    border: 2px solid #3d317a;
    &__edit-icon {
      position: absolute;
      margin: -7px 20px;
    }
    &__name {
      @include font-family-width(normal, 700, 16px, 24px, $black);
    }
  }
  &__big-avatar-90 {
    // @include big-avatar-by-size(90px, 90px);
    max-width: 90px;
    max-height: 90px;
    min-width: 90px;
    min-height: 90px;
    vertical-align: middle;
    display: inline-block;
    background: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    text-align: center;
    border-radius: 50%;
    &__img {
      vertical-align: middle;
      border-radius: 50%;
      // height: -webkit-fill-available;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    .active {
      border: 2px solid #3d317a;
    }
  }
  &__big-avatar-60 {
    @include big-avatar-by-size(60px, 60px);
    border: 2px solid $darkYellow;
    &__edit-icon {
      position: absolute;
      margin: -15px 35px;
    }
    .active {
      border: 2px solid #3d317a;
    }
  }
}
// ======== big avatar end ==========================

#overlay {
  background: black;
  color: #ffffff;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  opacity: 0.8;
  align-items: center;
  display: flex;
  justify-content: center;
}
.spinner {
  margin: 0 auto;
  height: 64px;
  width: 64px;
  animation: rotate 0.8s infinite linear;
  border: 5px solid $purple;
  border-right-color: transparent;
  border-radius: 50%;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// ------------- card slide and image grow styles -------------------------------------------

*:focus {
  outline: 0;
}
.card-body {
  padding: 10px 0px;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card-slide {
  display: inline-block;
  padding-bottom: 5px;
  margin-right: 10px;
}

.normal {
  width: 210px;
}

@media only screen and (min-width: 1170px) {
  .normal {
    width: calc(calc(100vw - 105px) / 6.293);
  }
}

article {
  background: var(--BG_COLOR_L1);
  overflow: hidden;
  position: relative;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  border-radius: 20px;
  transition: transform 0.25s cubic-bezier(0.33, 0.04, 0.63, 0.93),
    -webkit-transform 0.25s cubic-bezier(0.33, 0.04, 0.63, 0.93),
    -o-transform 0.25s cubic-bezier(0.33, 0.04, 0.63, 0.93);
}

article .thumbnail-container {
  position: relative;
}

.normal .card-img-container {
  padding-bottom: calc(0.56471 * 100%);
}
article .card-img-container {
  position: relative;
}
article .card-img-container img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  display: inline-block;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.lazy-img-loader.loaded {
  opacity: 1;
  transition: opacity 0.1s ease-in;
}

article:hover {
  z-index: 4;
  transform: scale3d(1.1, 1.1, 1) translate3d(0, 0, 0) perspective(500px);
}
.arrowsPoint {
  cursor: pointer;
}
.scroll-menu-arrow {
  margin: 0 40px;
}
.menu-wrapper {
  margin: 0px;
}
.menu-wrapper--inner {
  margin: 20px 0;
}

.card-active article:hover {
  z-index: 4;
  transform: scale3d(1.1, 1.1, 1) translate3d(5%, 0, 0) perspective(500px);
}
.card-last article:hover {
  z-index: 4;
  transform: scale3d(1.1, 1.1, 1) translate3d(-5%, 0, 0) perspective(500px);
}

.meta-desc {
  padding: 15px;
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.meta-wrapper {
  margin-right: 0;
  z-index: 1;
}
.c-title {
  @include font-family-width(normal, 700, 20px, 20px, $white);
}

.ellipsize {
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
}

.bg-warning {
  background: $darkYellow; //progrss bar color
}

.card_image_size {
  height: 320px;
  width: 220px;
}

//--------------------- Loader styles --------------------------------

.loader-container {
  // background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #ffffff;
  overflow: hidden;
  text-align: center;
}
.react-bodymovin-container {
  width: 100%;
  height: 100%;
  svg {
    width: 40% !important;
  }
}

.menu-item {
  margin: 20px;
}
.scroll-menu-arrow--disabled {
  visibility: hidden;
}

//======== web: horizontal scroll menu item styles start =========================
.hsvi {
  &__img-container {
    position: relative;
    height: 300px;
    width: 220px;
    margin: 0px 20px 10px 0px;
    border-radius: 10px;
    cursor: pointer;
    background: var(--BG_COLOR_L1);
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    transition: transform 0.25s cubic-bezier(0.33, 0.04, 0.63, 0.93),
      -webkit-transform 0.25s cubic-bezier(0.33, 0.04, 0.63, 0.93),
      -o-transform 0.25s cubic-bezier(0.33, 0.04, 0.63, 0.93);
  }

  &__card-img {
    position: absolute;
    height: 300px;
    width: 220px;
    border-radius: 10px;
  }
  &__dark-gradient {
    position: absolute;
    width: 100%;
    height: 160px;
    bottom: 0px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  }
  &__card-content {
    position: absolute;
    bottom: 0px;
  }
  &__card-playicon {
    margin: 0 20px 8px 20px;
  }
  &__card-title {
    margin: 0 20px 8px 20px;
    @include font-family-width(normal, 700, 20px, 20px, $white);
  }
  &__card-info {
    display: flex;
    justify-content: space-between;
    width: 180px;
    margin: 0 20px 20px 20px;
  }
  &__card-year {
    @include font-family-width(normal, 400, 14px, 18px, $white);
  }
  &__card-duration {
    width: 53px;
    height: 20px;
    background: $darkOrange;
    text-align: center;
    padding-top: 3px;
    border-radius: 4px;
    @include font-family-width(normal, 400, 12px, 12px, $white);
  }
  &__tvshow-info {
    margin: 0 20px 20px 20px;
    @include font-family-width(normal, 400, 14px, 20px, $white);
  }
}
.hshi {
  &__cw-img-container {
    position: relative;
    height: 200px;
    width: 280px;
    margin: 0px 20px 10px 0px;
    border-radius: 20px;
    cursor: pointer;
    background: var(--BG_COLOR_L1);
    overflow: hidden;
    position: relative;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    border-radius: 20px;
    transition: transform 0.25s cubic-bezier(0.33, 0.04, 0.63, 0.93),
      -webkit-transform 0.25s cubic-bezier(0.33, 0.04, 0.63, 0.93),
      -o-transform 0.25s cubic-bezier(0.33, 0.04, 0.63, 0.93);
  }
  &__cw-img {
    position: absolute;
    height: 200px;
    width: 280px;
    border-radius: 10px;
  }
  &__cw-dark-gradient {
    position: absolute;
    width: 280px;
    height: 160px;
    bottom: 0px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  }
  &__cw-content {
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
  &__cw-playicon {
    margin: 0 20px 17px 20px;
  }
  &__cw-details {
    margin: 0 20px 8px 20px;
    @include font-family-width(normal, 700, 20px, 20px, $white);
  }
  &__cw-item-type {
    display: inline-block;
    width: 68px;
    height: 24px;
    background-color: $red80;
    text-align: center;
    margin: 0 20px 8px 20px;
    border-radius: 4px;
    @include font-family-width(normal, 400, 14px, 20px, $white);
  }
  &__cw-progress-bar {
    margin: 0 20px 20px 20px;
  }
}
.card-progress-bar {
  height: 4px !important;
  margin-top: 5px;
}
.card-middle:hover {
  z-index: 4;
  transform: scale3d(1.1, 1.1, 1) translate3d(0, 0, 0) perspective(500px);
}
.card-first:hover {
  z-index: 4;
  transform: scale3d(1.1, 1.1, 1) translate3d(5%, 0, 0) perspective(500px);
}
.card-last:hover {
  z-index: 4;
  transform: scale3d(1.1, 1.1, 1) translate3d(-5%, 0, 0) perspective(500px);
}
//======== web: horizontal scroll menu item styles end =========================

// ======== mobile: horizontal scroll style start =================
.horizontal-slide {
  &__hs-manual {
    display: none;
  }
}
.customCarousel .customCarouselMain .carousel-control-prev,
.customCarousel .customCarouselMain .carousel-control-next {
  background-color: $white;
  opacity: 0.3 !important;
}
.customCarousel .customCarouselMain .carousel-control-prev:hover,
.customCarousel .customCarouselMain .carousel-control-prev:hover {
  background-color: $white;
  opacity: 0.4 !important;
}
.mainCarousel img {
  //  border-radius: 30px !important;
}
@media only screen and (max-width: 479px) {
  .horizontal-slide {
    &__hs-plugin {
      display: none;
    }
    &__hs-manual {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
    }
    &__card {
      width: 175px;
      height: 225px;
      margin: 0 5px;
    }
    &__card-pic {
      width: 175px;
      height: 225px;
      border-radius: 10px;
    }
  }
}
@media only screen and (max-width: 780px) and (min-width: 480px) {
  .horizontal-slide {
    &__hs-plugin {
      display: none;
    }
    &__hs-manual {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
    }
    &__card {
      width: 175px;
      height: 225px;
      margin: 0 5px;
    }
    &__card-pic {
      width: 175px;
      height: 225px;
      border-radius: 10px;
    }
  }
}
.linksContent {
  padding-top: 100px;
}
.webLogo {
  width: 95px !important;
  height: 65px !important;
  margin-top: 5px;
}

/** coming_soon popup **/

.coming_soon {
  .modal-content {
    padding: 0px !important;
  }
 

}

/** video Player **/
.video_player_thumb {
  margin-bottom: 60px;
  position: relative;
  cursor: pointer;
  img {
    width: 100%;
  }
  &::before {
    content: '';
    position: absolute;
    background-image: url(../../assets/images/Playiconorange.svg);
    background-position: center;
    background-size: 100%;
    width: 50px;
    height: 50px;
    left: calc(100% / 2 - 25px);
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.video_player {
  .close_button {
    position: absolute;
    right: -20px;
    top: -20px;
    z-index: 999;
    @include extraSmallDisplay() {
      right: -4px;
      top: -40px;
    }
  }

  .modal-content {
    padding: 0px !important;
    .container {
      padding: 0px;
    }
    .about_player {
      .player__player-container {
        width: 100% !important;
        height: 450px;
        @include smallDisplay() {
          height: 300px;
        }
        @include extraSmallDisplay() {
          height: 194px;
        }
      }
    }
  }
}
.makeCornerEdges {
  border-radius: 10px;
}

// .EventPage{
//   margin-top: 28%;
//   // display: none;
// }

