@font-face {
	font-family: 'KOMTITP';
	src: local('KOMTITW'), url('../fonts/KOMTITW/KOMTITW.woff') format('woff');
}

.audio{
	position: absolute;
    bottom: -2%;
	right: 20px;
	background: none;
	border: none;
	
}
.ad-bg{
	background: #445bef;
	width: 48px;
	height: 48px;
	border-radius: 20px;
	padding: 5px;
}

.pain-btn{
	position:relative;
	bottom: 250px;
	margin: auto;
	text-align: center;
}

.conan-btn{
	position:relative;
	bottom: 80px;
	margin: auto;
	text-align: center;
}

.paintbrush-bk-main{
	width: 300px;
	margin: 50px auto 0px auto;
}

.bk-btn{
	text-align: center;
	margin-bottom: 50px;
	margin-bottom:30%;
	margin-left: -200%;
	
}



.pain-btn a img:hover ,.conan-btn a img:hover ,.nxtbtns a img:hover {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.page-bgs{
	width: 100%;
	background: url("../images/event/paintathon-list-page.jpg");
	background-size: cover;
	background-position: center;
	
}

.pain-win-yrs{
	background: url("../images/event/winner-list-bg.png");
	background-position: top;
	background-repeat: no-repeat;
	width: 100%;
	height: 800px;
	margin-top: 130px;
}

.win-list-box{
	text-align: center;
	padding-top: 190px;
}

.win-tit{
	font-family: 'KOMTITP';
	color: #fff;
	background: #fe830a;
	display: inline;
  	padding: 5px 20px;
	font-weight: bold;
	letter-spacing: 1px;
	font-size: 18px;
	border-radius: 30px;
	text-align: center;
	/* margin-bottom: 20px; */
}

.pain-yrs{
	margin-top: 50px;
	margin-bottom: 15px;
}

.pain-yrs a{
	font-size: 24px;
	font-family: 'KOMTITP';
	color: #000;
	background: #f1f8e8;
	padding: 10px 30px;
	border-radius: 30px;
	font-weight: bold;
	text-decoration: none;
}

.bk-btn a{
	font-size: 14px;
	font-family: 'KOMTITP';
	color: #000;
	background: #fff;
	padding: 10px 20px;
	border-radius: 30px;
	text-decoration: none;
	
}
.paintboxmain{
	text-align: center;
  margin-top: -300px;
  margin-left: -200%;
  margin-bottom: 2%;
}


.pain-yrs a:hover,.bk-btn a:hover{
	color: #f94e31;
	
}

.yrs21 a,.yrs22 a,.yrs24 a{
	color: #1F51FF;
	box-shadow: 1px 2px 2px 1px #333;
	border: 3px solid #f0c20e;
}

.pain-win-list{
	background: url("../images/event/winner-board.png");
	background-position: top;
	background-repeat: no-repeat;
	width: 100%;
	height: 800px;
	margin-top: 130px;
}

.pain-win-list-22{
	background: url("../images/event/winner-board-22.png");
	background-position: top;
	background-repeat: no-repeat;
	width: 100%;
	height: 800px;
	margin-top: 130px;
}

.pain-win-list-21{
	background: url("../images/event/web-art-gal-bg.png");
	background-position: top;
	background-repeat: no-repeat;
	width: 100%;
	height: 800px;
	margin-top: 130px;
}

.mega-win-1st{
	position: relative;
	top: 40px;
	text-align: center;
	
}

.wd{
	width: 560px;
}

.list-22{
	position: relative;
	top: 55px!important;
	text-align: center;
}

.megawin{
	width: 90px;
	height: 75px;
	background: #000;
	margin: auto;
	border-radius: 20px;
	overflow: hidden;
	border: #fff 2px solid;
}

.megawin-tit{
	color: #ffe011;
	font-family: 'KOMTITP';
	font-size: 12px;
  	margin-top: 5px;
}

.winner-tit{
	color: #f5d83c;
	font-family: 'KOMTITP';
	font-size: 8px;
	/* width: 80px; */
	
}


.mega-win-1st h3{
	color: #fe830a;
	font-family: 'KOMTITP';
	font-size: 12px;
	
}

.gropbox{
	margin: auto;
	background: #000;
	border-radius: 20px;
	height: 70px;
	width: 80px;
	overflow: hidden;
	border: #fff 2px solid;
}

.gropbox img{
	width: 80px;
  height: 100px;
  object-fit: cover;
}



.Artisbox{
	
	height: 75px!important;
	width: 75px!important;
	
}

.conan-bgs{
    background: #151247;
	text-align: center;
	width: 100%;
	height:100%;
	margin-top: 40px;
	}

	
.conan-winlistbg{
	position: absolute;
	top: -525px;
	left: 80px;
	margin: auto;
	/* margin-top: -515px; */
}

.gp01{
	text-align: center;
	margin-top: 15px;
}

.gp01 h3 ,.grsbc h3{
	font-family: 'KOMTITP';
	color: #fff;
	font-size: 12px;
	background: #151247;
	display: inline;
	padding: 3px 8px;
	border-radius: 10px;
	border: #dac0d4 1px solid;
}

.win-name{
	font-family: 'KOMTITP';
	color: #fff;
	padding-top: 5px;
	font-size: 12px;
	padding-bottom: 5px;
	
}

.win-pic{
	width: 200px;
	height: 140px;
	border: #fff 3px solid;
	border-radius: 10px;
	background: #000;
	margin: auto;
	overflow: hidden;
}

.win-pic img{
	width: 200px;
	height: 140px;
	 object-fit: cover;
/*	object-fit: contain;*/
}



.grsbc{
	width: 250px;
	height: 160px;
	float: left;
	margin-top: 10px;
	
}

.modal-bg{
	background: #fff;
	border-radius:0px;
	border: #292478 10px solid;
	
}

.nopd{
	padding: 0px!important;
}
.drws{
	border-radius: 0px;
	overflow: hidden;
}

.modal-header{
	border-bottom: none!important;
	padding: 0px!important;
}

.drw-cand-name{
	text-align: center;
	padding: 10px;
	font-family: 'KOMTITP';
	font-size: 12px;
}

.modal-he-adj{
	margin-top: 0px;
}

.close-bg{
	background: #151247!important;
	position: absolute;
	right: 25px;
	top: 25px;
	z-index: 9999999;
	border-radius: 20px;
	padding: 0px 5px 5px 5px!important;
	margin: 0px;
	color: #fff;
}

.paintathon-modal-bg{
	border: #7c5ab2 10px solid!important;
	border-radius: 0px!important;
}


.zoom,.zoombt{
	transition: transform .3s ease;
}

.zoom:hover {
  transform: scale(1.05);
}

.zoombt:hover {
  transform: scale(1.1);
}

.nxtbtns{
	position: absolute;
	top: 75%;
	right: 45%;
}

.art-gal a{
	color: #000;
	font-family: 'KOMTITP';
	font-size: 12px;
	background: #fff;
	padding: 5px 10px;
	border-radius: 10px;
	
	
}

.arilist{
	height: 450px;
	overflow: auto;
	margin-top: 30px;
	
}


.artbox{
	margin-bottom: 15px;
	padding: 0px;
	float: left;

}

.mCSB_container {
  overflow: hidden;
  width: 100%!important;
  height: auto;
}

@media (min-width: 1400px) {
.conan-winlistbg {
/*    margin-top: -715px;*/
	position: absolute;
	top: -710px;
	left: 225px;
}
	
	.ml-xl{
		margin-left: 100px;
		
	}
	
	.win-pic {
    width: 240px;
    height: 150px;
    border: #fff 3px solid;
    border-radius: 10px;
    background: #000;
    margin: auto;
    overflow: hidden;
}
	
	.win-pic img {
    width: 240px;
    height: 190px;
    object-fit: cover;
	}
	
	.mt-xl{
		margin-top: 30px;
	}
	
	.container-fluid {
		margin-top: -2%;
		margin-bottom: -3%;
	}
	
	
}
.paintathon_img{
margin-top: 2%;
  }
  /* .art-gal {
    position: relative;
    display: inline-block;
    padding: 10px;
} */
  
.art-gal {
    margin: 10px;
}
