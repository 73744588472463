.detailsPageSections {
  &__Shows-View {
    &__SV-Slist {
      cursor: pointer;
      padding: 15px;
      margin-bottom: 15px;
      position: relative;
      &__SVS-img {
        border-radius: 10px;
        width: 140px;
        height: 150px;
      }
      &__SVS-img-container {
        text-align: center;
      }
      &__fav {
        display: block;
        position: absolute;
        right: 30px;
        z-index: 1;
      }
      &__favOnSmallDisplay {
        display: none;
      }
      &__Ep-year {
        display: none;
      }
      &__SV-text1 {
        font-size: 16px;
        color: $lightGrey;
        font-weight: 600;
      }
      &__SV-text3 {
        display: none;
      }
      &__SV-text2 {
        font-size: 16px;
        color: $lightBlue;
        font-weight: 500;
        padding-bottom: 10px;
        text-transform: uppercase;
        &__dot {
          width: 6px;
          height: 6px;
          background: $lightBlue;
          border-radius: 50%;
          display: inline-block;
          margin: 0 5px;
        }
        p {
          font-size: 14px;
          color: $lightGrey;
        }
      }
      &__show-name {
        font-family: 'Baloo 2', cursive;
        color: $black;
        font-size: 24px;
        padding-bottom: 10px;
        margin: 0px;
      }
      &__SV-desc {
        @include font-family-width(normal, 400, 14px, 21px, $lightGrey);
      }
      &__epi_desc {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &__SV-Slist.active {
      background-color: $lightWhite;
    }
    &__SV-Slist:hover {
      background-color: $lightWhite;
    }

    &__SV-RT {
      height: 500px !important;
      background: $shadedPurple;
      border-radius: 12px;
      height: 100%;
      padding: 20px;
      overflow: hidden;
      position: relative;
      color: $white;

      &__SVR-text1 {
        font-size: 18px;
        color: $white;
        padding: 15px 0;
      }
      &__SVR-btn {
        background: $orange;
        border: 1px solid $orange;
        border-radius: 4px;
        font-size: 16px;
        color: $white;
        font-weight: 500;
        text-align: center;
        padding: 6px 10px;
        margin-right: 10px;
      }
      ul {
        padding: 20px 0 20px 40px;
      }
      ul li {
        list-style-type: none;
        font-size: 12px;
        position: relative;
        padding-bottom: 20px;
      }
      ul li:before {
        content: '';
        background-image: url('../../../assets/images/TS-asset9.svg');
        display: block;
        width: 25px;
        height: 25px;
        position: absolute;
        left: -32px;
        background-repeat: no-repeat;
      }
    }

    &__SV-Snav {
      padding-bottom: 15px;
      a {
        width: 120px;
        background: $lightSilver;
        border-radius: 12px;
        font-size: 16px;
        color: $grey80;
        display: inline-block;
        text-align: center;
        padding: 5px;
        text-transform: uppercase;
        margin-right: 10px;
        text-decoration: none;
      }
      a.active {
        background-color: $purple87;
        color: $white;
      }
      &__customWidth {
        cursor: pointer;
        width: 150px !important;
        padding: 5px;
        text-transform: uppercase;
        margin-right: 10px;
        text-decoration: none;
        border-radius: 14px;
        background-color: $purple87;
        color: $white;
      }
    }
  }

  &__video-content-type {
    @include font-family-width(normal, 500, 16px, 20px, $white);
    display: inline-block;
    min-width: 30px;
    min-height: 5px;
    background: $purple87;
    border-radius: 4px;
    padding: 0px 4px;
  }
  &__share-icon-container {
    cursor: pointer;
    @include font-family-width(normal, 400, 14px, 18px, $black);
  }
  &__share-icon {
    margin: 0 2px 2px 0;
  }
  &__video-title {
    @include font-style-Baloo(normal, 400, 24px, 31px, $black);
    margin-top: 3px;
    margin-bottom: 4px;
    .title-meta-info {
      margin-left: 22px;
      picture {
        position: relative;
        padding: 5px;
        border-radius: 20px;
        height: 32px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      picture:nth-child(1) {
        background: rgba(249, 38, 93, 0.1);
      }
      picture:nth-child(2) {
        background: rgba(33, 180, 58, 0.1);
      }
    }
  }
  &__video-sub-title {
    @include font-family-width(normal, 600, 16px, 21px, $black);
    margin-bottom: 6px;
  }
  &__video-info {
    @include font-family-width(normal, 500, 16px, 16px, #2f80ed);
    margin-bottom: 16px;
  }
  &__video-desc {
    @include font-family-width(normal, 400, 14px, 21px, $lightGrey);
    margin-bottom: 20px;
  }
  &__video-desc-details {
    width: 60%;
  }
  &__video-desc-more {
    @include font-family-width(normal, 600, 14px, 21px, $lightBlue);
  }
  &__video-container {
    margin: 0px 16px;
  }
  &__bannerImage {
    margin-top: 30px;
  }
  &__movie-share-container {
    margin-bottom: 16px;
  }
  .userContent strong {
    font-weight: normal !important;
  }
  .userContent span a {
    text-decoration: none;
    color:#2f80ed;
  }  
}

.tone-card {
  position: relative;
  z-index: 1;
  .nav-pills {
    margin-bottom: 16px;
    @include smallDisplay() {
      &[role='tablist'] {
        flex-wrap: nowrap;
        overflow-y: auto;
        white-space: nowrap;
        padding-bottom: 10px;
      }
    }
    .nav-link {
      @include font-family-Poppins;
      font-size: 18px;
      padding: 7px 21px;
      text-transform: uppercase;
      color: #ff85a5;
      @include smallDisplay() {
        font-size: 12px;
        line-height: 18px;
        padding: 6px 10px;
      }
      &.active {
        color: $white;
        background: #ff85a5;
        border-radius: 20px;
      }
    }
  }
  &.shows {
    .block-title {
      @include font-style-Baloo(normal, 400, 32px, 50px, #4a5060);
      text-transform: uppercase;
      margin-bottom: 20px;
      @include smallDisplay() {
        @include font-style-Baloo(normal, 400, 16px, 25px, #4a5060);
      }
    }
    .block-description {
      @include font-family-Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #000000;
      margin-bottom: 40px;
    }
    .block-subtitle {
      @extend .block-description;
      font-size: 18px;
      line-height: 27px;
      text-transform: uppercase;
    }
    // show card
    .show-card {
      margin-bottom: 26px;
      &:hover .banner-img-hover-action {
        display: block;
      }
      .banner-img {
        position: relative;
        width: 100%;
        border-radius: 10px;
        &-hover-action {
          display: none;
          height: 55px;
          position: absolute;
          bottom: 0;
          width: 100%;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 18.63%,
            $black 91.9%
          );
          border-radius: 10px;
          .play-btn {
            background-repeat: no-repeat;
            min-height: 55px;
            img {
              margin-left: 16px;
            }
            &::after {
              content: '';
              position: absolute;
              left: 10px;
              top: 8px;
              cursor: pointer;
            }
          }
        }
      }
      .title {
        @include font-family-Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: $black;
        margin-top: 12px;
        margin-bottom: 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .card-meta-info {
        @include font-family-Poppins;
        color: #909090;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        img {
          cursor: pointer;
          height: 20px;
          width: 20px;
        }
      }
    }
    // trailer card
    .trailer-card {
      display: flex;
      margin-bottom: 26px;
      @include smallDisplay() {
        display: block;
      }
      &:hover .banner-img-hover-action {
        display: block;
      }
      .banner-img {
        width: 260px;
        margin-right: 30px;
        position: relative;
        max-height: 160px;
        img {
          width: 100%;
          max-height: 160px;
        }
        @include smallDisplay() {
          width: 100%;
          margin-right: 0;
          margin-bottom: 16px;
        }
        &-hover-action {
          display: none;
          height: 55px;
          position: absolute;
          bottom: 0;
          width: 260px;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 18.63%,
            $black 91.9%
          );
          border-radius: 10px;
          @include smallDisplay() {
            width: 100%;
          }
          .play-btn {
            position: absolute;
            left: 10px;
            top: 8px;
            cursor: pointer;
          }
        }
      }
      .calc-width {
        width: calc(100% - 360px);
        @include smallDisplay() {
          width: 100%;
        }
      }
      .title {
        display: block;
        width: 100%;
        @include font-style-Baloo(normal, 400, 24px, 30px, $black);
        @include smallDisplay() {
          @include font-style-Baloo(normal, 400, 16px, 21px, $black);
        }
      }
      .subtitle {
        display: block;
        width: 100%;
        @include font-family-Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: $black;
        margin-top: 12px;
        margin-bottom: 8px;
        @include smallDisplay() {
          font-size: 12px;
          line-height: 16px;
        }
      }
      .description {
        display: block;
        width: 100%;
        font-weight: 300;
        @include font-family-Poppins;
        font-size: 14px;
        line-height: 21px;
        color: #747474;
        margin-bottom: 0;
        @include smallDisplay() {
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 20px;
        }
      }
      .card-meta-info {
        picture {
          position: relative;
          padding: 5px;
          border-radius: 20px;
          height: 32px;
          width: 30px;
        }
        picture:nth-child(1) {
          background: rgba(249, 38, 93, 0.1);
        }
        picture:nth-child(2) {
          background: rgba(33, 180, 58, 0.1);
        }
      }
    }
    // movie card
    .movie-card {
      background: $white;
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      margin-bottom: 30px;
      &:hover .play-btn {
        display: block;
      }
      &-bg {
        height: 250px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-size: cover;
        background-position: center center;
        position: relative;
        @include mediumDisplay() {
          height: 160px;
        }
        @include smallDisplay() {
          height: 160px;
        }
        .play-btn {
          display: none;
          position: absolute;
          left: 20px;
          bottom: -16px;
          cursor: pointer;
          @include smallDisplay() {
            left: 10px;
          }
        }
      }
      &-name {
        padding: 20px 16px 20px 20px;
        font-size: 16px;
        font-weight: 400;
        color: $black;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        @include mediumDisplay() {
          padding: 10px;
        }
        @include smallDisplay() {
          padding: 10px;
        }
      }
    }
    // character-list
    .character-list {
      padding-left: 0;
      li {
        width: 100%;
        display: block;
        margin-bottom: 40px;
        .character-block {
          position: relative;
          background: rgba(232, 232, 232, 0.5);
          border-radius: 10px;
          padding: 16px 24px 16px 100px;
          margin-left: 40px;
          @include smallDisplay() {
            padding: 60px 16px 24px 16px;
            margin-left: 0;
          }
          img {
            max-width: 120px;
            position: absolute;
            left: -40px;
            top: 0;
            @include smallDisplay() {
              max-width: 80px;
              left: 16px;
              top: -30px;
            }
          }
          .description {
            h5 {
              @include font-style-Baloo(normal, 400, 24px, 38px, $black);
              @include smallDisplay() {
                @include font-style-Baloo(normal, 400, 16px, 28px, $black);
              }
            }
            p {
              @include font-family-Poppins;
              font-size: 14px;
              line-height: 21px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 479px) {
  .detailsPageSections {
    &__bannerImage {
      margin-top: 25px;
    }
    &__video-title {
      @include font-style-Baloo(normal, 400, 20px, 25px, $black);
    }
    &__video-sub-title {
      @include font-family-width(normal, 600, 14px, 18px, $lightGrey);
    }
    &__video-info {
      @include font-family-width(normal, 500, 12px, 16px, $lightBlue);
    }
    &__video-desc {
      @include font-family-width(normal, 400, 12px, 18px, $lightGrey);
    }
    &__video-desc-details {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
    &__Shows-View {
      &__SV-Slist {
        &__SV-desc {
          display: none;
        }
        &__SV-text1 {
          display: none;
        }
        &__Ep-year {
          display: block;
          @include font-family-width(normal, 400, 12px, 16px, $lightGrey);
        }
        &__SVS-img {
          border-radius: 10px;
          width: 150px;
          height: 100px;
        }
        &__fav {
          display: none;
        }
        &__favOnSmallDisplay {
          display: block;
          position: absolute;
          right: 10px;
          z-index: 1;
        }
        &__SV-text2 {
          display: none;
        }
        &__SV-text3 {
          display: inline-block;
          @include font-family-width(normal, 400, 12px, 16px, $lightBlue);
          text-transform: uppercase;
        }
        &__dot {
          width: 6px;
          height: 6px;
          background: $lightBlue;
          border-radius: 50%;
          display: inline-block;
          margin: 0 5px;
        }
        &__show-name {
          @include font-style-Baloo(normal, 400, 14px, 18px, $black);
          padding-bottom: 0px;
        }
      }
      &__SV-Slist.active {
        background-color: $white;
      }
      &__SV-Slist:hover {
        background-color: $white;
      }
    }
    &__plan-Upgrade {
      display: none;
    }
  }
}
@media only screen and (max-width: 780px) and (min-width: 480px) {
  .detailsPageSections {
    &__bannerImage {
      margin-top: 25px;
    }
    &__video-title {
      @include font-style-Baloo(normal, 400, 20px, 25px, $black);
    }
    &__video-sub-title {
      @include font-family-width(normal, 600, 14px, 18px, $lightGrey);
    }
    &__video-info {
      @include font-family-width(normal, 500, 12px, 16px, $lightBlue);
    }
    &__video-desc {
      @include font-family-width(normal, 400, 12px, 18px, $lightGrey);
    }
    &__video-desc-details {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
    &__Shows-View {
      &__SV-Slist {
        &__SV-desc {
          display: none;
        }
        &__SV-text1 {
          display: none;
        }
        &__Ep-year {
          display: block;
          @include font-family-width(normal, 400, 12px, 16px, $lightGrey);
        }
        &__SVS-img {
          border-radius: 10px;
          width: 150px;
          height: 100px;
        }
        &__fav {
          display: none;
        }
        &__favOnSmallDisplay {
          display: block;
          position: absolute;
          right: 10px;
          z-index: 1;
        }
        &__SV-text2 {
          display: none;
        }
        &__SV-text3 {
          display: inline-block;
          @include font-family-width(normal, 400, 12px, 16px, $lightBlue);
          text-transform: uppercase;
        }
        &__dot {
          width: 6px;
          height: 6px;
          background: $lightBlue;
          border-radius: 50%;
          display: inline-block;
          margin: 0 5px;
        }
        &__show-name {
          @include font-style-Baloo(normal, 400, 14px, 18px, $black);
          padding-bottom: 0px;
        }
      }
      &__SV-Slist.active {
        background-color: $white;
      }
      &__SV-Slist:hover {
        background-color: $white;
      }
    }
    &__plan-Upgrade {
      display: none;
    }
  }
}
