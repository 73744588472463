.moviesDetails {
  &__Shows-View {
    &__SV-RT {
      height: 500px;
      background: $shadedPurple;
      border-radius: 12px;
      height: 100%;
      padding: 20px;
      overflow: hidden;
      position: relative;
      color: $white;

      &__SVR-text1 {
        font-size: 18px;
        color: $white;
        padding: 15px 0;
      }
      &__SVR-btn {
        background: $orange;
        border: 1px solid $orange;
        border-radius: 4px;
        font-size: 16px;
        color: $white;
        font-weight: 500;
        text-align: center;
        padding: 6px 10px;
        margin-right: 10px;
      }
      ul {
        padding: 20px 0 20px 40px;
      }
      ul li {
        list-style-type: none;
        font-size: 12px;
        position: relative;
        padding-bottom: 20px;
      }
      ul li:before {
        content: '';
        background-image: url('../../../assets/images/TS-asset9.svg');
        display: block;
        width: 25px;
        height: 25px;
        position: absolute;
        left: -32px;
        background-repeat: no-repeat;
      }
    }

    &__SV-Snav {
      padding-bottom: 15px;
      a {
        width: 120px;
        background: $lightSilver;
        border-radius: 12px;
        font-size: 16px;
        color: $grey80;
        display: inline-block;
        text-align: center;
        padding: 5px;
        text-transform: uppercase;
        margin-right: 10px;
        text-decoration: none;
      }
      a.active {
        background-color: $purple87;
        color: $white;
      }
      &__customWidth {
        cursor: pointer;
        width: 150px !important;
        padding: 5px;
        text-transform: uppercase;
        margin-right: 10px;
        text-decoration: none;
        border-radius: 14px;
        background-color: $purple87;
        color: $white;
      }
    }
  }
  &__playerBackBtn {
    display: none;
  }
  &__player {
    display: block;
    position: relative;
  }
  &__favouriteIcon {
    position: absolute;
    right: 60px;
    top: 20px;
    z-index: 1;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }
  &__Movies-trailer {
    margin-top: 14px;
    margin-bottom: 20px;
    @include font-style-Baloo(normal, 400, 24px, 31px, $black);
  }
  &__Movies-trailer-block {
    display: flex;
  }
  &__trailer-card-block {
    width: 380px;
    height: 250px;
    position: relative;
    cursor: pointer;
  }
  &__trailer-img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }
  &__dark-gradient {
    position: absolute;
    width: 100%;
    height: 160px;
    bottom: 0px;
    border-radius: 0px 0px 20px 20px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  }
  &__trailer-info {
    position: absolute;
    bottom: 0;
    min-height: 50%;
    width: 100%;
    padding: 0 5%;
  }
  &__trailer-title {
    margin: 10px 0;
    @include font-family-width(normal, 700, 18px, 24px, $white);
  }
  &__trailer-txt-duration {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  &__trailer-txt {
    @include font-family-width(normal, 400, 14px, 18px, $white);
  }
  &__trailer-duration {
    width: 53px;
    height: 20px;
    background: $darkOrange;
    text-align: center;
    padding-top: 3px;
    border-radius: 4px;
    @include font-family-width(normal, 400, 12px, 12px, $white);
  }

  &__video-content-type {
    @include font-family-width(normal, 500, 16px, 20px, $white);
    display: inline-block;
    min-width: 30px;
    min-height: 5px;
    background: $purple87;
    border-radius: 4px;
    padding: 0px 4px;
  }
  &__movie-share-container {
    margin-bottom: 16px;
  }
  &__share-icon-container {
    cursor: pointer;
    @include font-family-width(normal, 400, 14px, 18px, $black);
  }
  &__share-icon {
    margin: 0 2px 2px 0;
  }
  &__video-title {
    @include font-style-Baloo(normal, 400, 24px, 31px, $black);
    margin-top: 3px;
    margin-bottom: 4px;
    .title-meta-info {
      margin-left: 22px;
      picture {
        position: relative;
        padding: 5px;
        border-radius: 20px;
        height: 32px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      picture:nth-child(1) {
        background: rgba(249, 38, 93, 0.1);
      }
      picture:nth-child(2) {
        background: rgba(33, 180, 58, 0.1);
      }
    }
  }
  &__video-sub-title {
    @include font-family-width(normal, 600, 16px, 21px, $black);
    margin-bottom: 6px;
  }
  &__video-info {
    @include font-family-width(normal, 500, 16px, 16px, #2F80ED);
    margin-bottom: 16px;
  }
  &__video-desc {
    @include font-family-width(normal, 400, 14px, 21px, $lightGrey);
    margin-bottom: 20px;
  }
  &__video-desc-details {
    width: 60%;
  }
  &__video-desc-more {
    @include font-family-width(normal, 600, 14px, 21px, $lightBlue);
  }
  &__video-container {
    margin: 0px 16px;
  }
  &__bannerImage {
    margin-top: 30px;
  }
  &__janeBg {
    position: relative;
  }
  &__janeBgImg {
    position: absolute;
    top: 100px;
    right: 0px;
  }
}
@media only screen and (max-width: 479px) {
  .moviesDetails {
    &__playerBackBtn {
      display: block;
      position: absolute;
      left: 15px;
      top: 15px;
      z-index: 1;
    }
    &__favouriteIcon {
      position: absolute;
      right: 15px;
      top: 15px;
      z-index: 1;
      cursor: pointer;
      background-color: transparent;
      border: none;
    }
    &__bannerImage {
      margin-top: 25px;
    }
    &__janeBg {
      display: none;
    }
    &__video-title {
      @include font-style-Baloo(normal, 400, 20px, 25px, $black);
    }
    &__video-sub-title {
      @include font-family-width(normal, 600, 14px, 18px, $lightGrey);
    }
    &__video-info {
      @include font-family-width(normal, 500, 12px, 16px, $lightBlue);
    }
    &__video-desc {
      @include font-family-width(normal, 400, 12px, 18px, $lightGrey);
    }
    &__video-desc-details {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
    &__trailer-card-block {
      max-width: 273px;
      height: 200px;
    }
    &__dark-gradient {
      height: 55%;
    }
    &__trailer-info {
      padding: 0 15px 15px 20px;
    }
    &__trailer-title {
      @include font-family-width(normal, 700, 14px, 24px, $white);
    }
  }
}
@media only screen and (max-width: 780px) and (min-width: 480px) {
  .moviesDetails {
    &__playerBackBtn {
      display: block;
      position: absolute;
      left: 30px;
      top: 30px;
      z-index: 1;
      cursor: pointer;
      background-color: transparent;
      border: none;
    }
    &__favouriteIcon {
      position: absolute;
      right: 30px;
      top: 30px;
      z-index: 1;
      cursor: pointer;
      background-color: transparent;
      border: none;
    }
    &__bannerImage {
      margin-top: 25px;
    }
    &__janeBg {
      display: none;
    }
    &__video-title {
      @include font-style-Baloo(normal, 400, 20px, 25px, $black);
    }
    &__video-sub-title {
      @include font-family-width(normal, 600, 14px, 18px, $lightGrey);
    }
    &__video-info {
      @include font-family-width(normal, 500, 12px, 16px, $lightBlue);
    }
    &__video-desc {
      @include font-family-width(normal, 400, 12px, 18px, $lightGrey);
    }
    &__video-desc-details {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
    &__trailer-card-block {
      max-width: 273px;
      height: 200px;
    }
    &__dark-gradient {
      height: 100px;
    }
    &__trailer-info {
      padding: 0 15px 15px 20px;
    }
    &__trailer-title {
      @include font-family-width(normal, 700, 14px, 24px, $white);
    }
  }
}
