.enterPin {
  &__container {
    background: $white;
    border-radius: 50px;
    padding: 30px;
  }
  &__back-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    &__back-text {
      padding-left: 0.625rem;
      font-weight: 600;
      font-style: normal;
      font-size: 1.125rem;
      line-height: 1.25rem;
      color: $lightGrey;
    }
  }
  &__heading {
    font-size: 18px;
    font-weight: 700;
    line-height: 23.25px;
    color: $lightPurple7;
  }
  &__subText {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $grey80;
  }
  &__subTextDescription {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: $grey90;
  }
  &__subLinks {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: $skyBlue;
    cursor: pointer;
  }
}
