.helpAndSupport {
  &__container {
    background: $white;
    border-radius: 50px;
    padding: 30px;
  }

  &__back-btn {
    display: flex;
    align-items: center;
    cursor: pointer;

    &__back-text {
      padding-left: 0.625rem;
      font-weight: 600;
      font-style: normal;
      font-size: 1.125rem;
      line-height: 1.25rem;
      color: $lightGrey;
    }
  }

  &__heading {
    font-size: 18px;
    font-weight: 700;
    line-height: 23.25px;
    color: $lightPurple7;
  }

  &__accordianCustom {
    height: 555px;
    // overflow: auto;
    overflow-y: scroll;
    scrollbar-width: thin !important;
    // scrollbar-color: blue orange;
    background-color: $whiteFa;
    border-radius: 10px;
    padding: 10px;

    .scrollable-element {
      scrollbar-width: thin;
    }
  }

  .card {
    border: 0px solid rgba(0, 0, 0, 0) !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
    cursor: pointer;

    .card-header {
      background-color: $whiteFa;
    }

    .card-body {
      background-color: $whiteFa;
      padding: 0.2rem 0rem 0rem 1rem !important;
    }

    .customBody {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
    }

    .customHedder {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  .bbAddress {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 5px;

    .teleCustom {
      height: 15px;
      width: 15px;
      left: 2.5px;
      top: 2.5px;
      border-radius: 0px;
      color: $green;
    }

    .customTextArea {
      height: 140px !important;
      background-color: yellow;
    }
  }
}

.map-container {
  margin-top: 10px;
}
.mapdirections {
  text-align: left;
  @include font-family-width(normal, 700, 12px, 16px, $black);
}
.getdirections {
  text-align: right;
  @include font-family-width(normal, 600, 12px, 20px, $skyBlue);
}
.gmap-container {
  margin-top: 10px;
  height: 180px;
  width: 100%;
}
.help-fb-concern {
  text-transform: none;
  @include font-family-width(normal, 600, 14px, 21px, $grey80);
}
.help-fb-conntainer {
  text-align: center;
  background-color: $textAreaBg !important;
  border-radius: 20px;
  padding: 10px;
}
.help-feedback-comments-input {
  max-width: 352px;
  height: 110px !important;
  background-color: rgba(196, 196, 196, 0.2);
  border: none !important;
  background-color: $textAreaBg !important;
}
.fb-submit-btn {
  margin-top: 5px;
  position: relative;
  border: none !important;
  background-color: transparent;
  @include font-family-width(normal, 600, 16px, 24px, $red05);
}
.h-line {
  height: 1px;
  width: 100%;
  border: 1px solid #dedede;
}
