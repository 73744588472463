.menuLanguages {
  &__container {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    text-align: center;
    margin: 10px 0;
    cursor: pointer;
  }
  &__container-active {
    width: 120px;
    height: 120px;
    background: #ffffff;
    border: 2px solid #663ab7;
    box-sizing: border-box;
    box-shadow: 0px 0px 15px rgba(102, 58, 183, 0.15);
    border-radius: 50%;
    text-align: center;
    margin: 10px 0;
    cursor: pointer;
  }
  &__img {
    height: 45px;
    width: 60px;
    margin-top: 25px;
  }
  &__title {
    padding-top: 5px;
    text-transform: uppercase;
    @include font-style-Baloo(normal, 500, 14px, 16px, $black);
  }
  @media screen and (max-width: 767px) and (min-width: 200px) {
    .menuLanguages__img {
      height: 45px;
      width: 60px;
      margin-top: 6px;
    }
    .menuLanguages__container {
      height: 92px;
      width: 92px;
    }
  }
}
