.notificationSettings {
  &__container {
    background: $white;
    border-radius: 50px;
    padding: 30px 30px 60px 30px;
  }

  &__back-btn {
    display: flex;
    align-items: center;
    cursor: pointer;

    &__back-text {
      padding-left: 0.625rem;
      font-weight: 600;
      font-style: normal;
      font-size: 1.125rem;
      line-height: 1.25rem;
      color: $lightGrey;
    }
  }

  &__sub {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #505565;
    padding-bottom: 20px;
  }

  &__heading {
    font-size: 18px;
    font-weight: 700;
    line-height: 23.25px;
    color: $lightPurple7;
    padding-bottom: 40px;
  }
  .checkBox {
    float: right;
  }
}
